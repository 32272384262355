import React, { Component } from 'react';

class AboutUsComponent extends Component {
    render() {
        return (
            <div className="container">
                <div className="mt-5">
                    <h3 className="mt-2" style={{ color: '#5d4f9f', textAlign: 'center' }}>About Target Hunter</h3>
                    <div className="about">
                        <p>Advertising on <b>Facebook</b> is becoming a bit crowded, right?</p>
                        <p>Today over <b>6 million advertisers</b> are using the platform. And with good reason, because Facebook offers highly specific targeting capabilities combined with the massive reach to be able to scale winning campaigns.</p>
                        <p><b>They show the exact same 25 suggestions to everyone…</b>Every single one of these 6 million advertisers gets the exact same interest suggestions.</p>
                        <p>Do you think that targeting these 25 will give you the best results for your campaigns? Or do you think that by targeting them <b>you’ll enter a massive bidding war?</b> Of course, simplification is not Facebook’s only motivation…</p>
                        <p>There is a another way of doing this.</p>
                        <p><a href="/"><strong>Target Hunter</strong></a> can suggest you <b>unlimited hidden keywords from facebook</b> that will not only give you the best results for your campaigns but also speed-up your business with exponential rate.</p>

                        <a href="/" className="btn btn-success" >Search for keywords Now</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutUsComponent;