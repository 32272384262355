import React, { Component } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Main from './MainComponent';

class Router extends Component {
    state = {  }
    render() {
        const NotFound = () => (  <h1>404.. This page is not found!</h1>) 
        return ( 
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Main} />
                    <Route exact path="/:keyword" render={props => <Main {...props}/> }/>
                </Switch>
            </BrowserRouter>
         );
    }
}
 
export default Router;