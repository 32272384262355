import React, { Component } from 'react';
import Search from './SearchComponent';
import AdvanceSearch from './AdvanceSearchComponent';
import AboutUs from './AboutUsComponent';
import Contact from './ContactComponent';
import { Navbar, Form, Nav, Button, FormControl, Image } from 'react-bootstrap';

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            keyword: this.props.match.params.keyword,
        }
    }
    render() {
        const DisplayComponents = () => {
            if (this.props.match.params.keyword === undefined) {
                return (<Search />)
            } else if (this.props.match.params.keyword === 'about') {
                return (<AboutUs />)
            } else if (this.props.match.params.keyword === 'contact') {
                return (<Contact />)
            } else {
                return (<AdvanceSearch keyword={this.props.match.params.keyword} />)
            }
        }
        return (
            <div className="wrapper">
                <Navbar expand="lg">
                    <Navbar.Brand href="/"><Image src={require('../assets/Logo.png')} alt="TargetHunter" width="250"/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto"></Nav>
                        <Nav className="">
                            <Nav.Link href="/"><span className="links">Home</span></Nav.Link>
                            <Nav.Link href="/about"><span className="links">About Us</span></Nav.Link>
                            <Nav.Link href="/contact"><span className="links">Contact</span></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <DisplayComponents />
            </div>
        );
    }
}

export default Main;