import React from 'react';
import Router from './Components/RouterComponent';
import './scss/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga';

function App() {
  return (
    <Router />
  );
}

function initializeReactGA() {
  ReactGA.initialize('UA-162805849-1');
  ReactGA.pageview('/');
}

export default App;
