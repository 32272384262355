import React, { Component } from 'react';
import KeywordTable from './TableComponent';
import axios from 'axios';
import { FaSearch } from "react-icons/fa";

class SearchComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchKeyword: null,
            searchKeywordData: null,
            showTable: false,
            locale: null
        }
        this.handleSearch = this.handleSearch.bind(this)
        this.getData = this.getData.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    handleKeyPress(target) {
        if (target.charCode == 13) {
            this.handleSearch()
        }
    }

    handleSearch() {
        if (this.refs.searchInput.value) {
            this.setState({
                searchKeyword: this.refs.searchInput.value,
                locale: this.refs.locale.value
            }, () => {
                this.getData(this.state.searchKeyword, this.state.locale)
            })
        } else {
            this.setState({
                searchKeyword: null
            })
            alert("please enter a keyword to search");
        }
    }

    getData(keyword, locale) {
        axios({
            method: 'get',
            url: 'https://graph.facebook.com/search?type=adinterest&q=[' + keyword + ']&limit=10000&locale=' + locale + '&access_token=2840078976085595|mJkR2-rVYjp7TbFh2_ngn2FB_uM'
        }).then(res => {
            this.setState({
                searchKeywordData: res.data.data,
                showTable: true
            })
        })
    }
    render() {
        const { showTable, searchKeywordData, searchKeyword } = this.state
        return (
            <div className="Wrapper">
                <div className="container search-div">
                    <div className="search-div_headline">
                        <h3><a href="/">Target Hunter</a> can search for unlimited hidden keywords from facebook to speed-up your business</h3>
                    </div>
                    <div className="search-div_searchBox ">
                        <div className="row">
                            <div className="col-12 col-md-9 offset-md-1">
                                <input type="text" ref="searchInput" className="form-control" onKeyPress={this.handleKeyPress} placeholder="Enter a facebook interest..." />
                            </div>
                            <div className="col-12 col-md-2">
                                <button className="btn search-btn" onClick={this.handleSearch}><FaSearch className="search_icon" /> Explore</button>
                                {/* <button className="btn search-btn" onClick={this.handleSearch}><Image src={require('../assets/search.png')} /> Search</button> */}
                            </div>
                        </div>
                        <div className="bottom-div">
                            <div className="example">
                                <span className="ex">Example:</span>
                                <span className="exVal">Cricket</span>
                            </div>
                            <div className="locale-selector">
                                <label className="lable">Interest Locale:</label>
                                <select ref="locale" defaultValue="en_US">
                                    <option value="af_NA">Afghanistan</option>
                                    <option value="en-au">Australia</option>
                                    <option value="pt_BR">Brazil</option>
                                    <option value="zh_CN">China</option>
                                    <option value="zh_HK">China (Hong Kong)</option>
                                    <option value="cs_CZ">Czech Republic</option>
                                    <option value="ar_EG">Egypt</option>
                                    <option value="de_DE">Germany</option>
                                    <option value="da_DK">Denmark</option>
                                    <option value="fi_FI">Finland</option>
                                    <option value="fr_FR">France</option>
                                    <option value="el_GR">Greece</option>
                                    <option value="hu_HU">Hungary</option>
                                    <option value="id_ID">Indonesia</option>
                                    <option value="he_IL">Israel (Hebrew)</option>
                                    <option value="bn_IN">India (Bengali)</option>
                                    <option value="gu_IN">India (Gujarati)</option>
                                    <option value="hi_IN">India (Hindi)</option>
                                    <option value="ml_IN">India (Malayalam)</option>
                                    <option value="mr_IN">India (Marathi)</option>
                                    <option value="pa_IN">India (Panjabi)</option>
                                    <option value="ta_IN">India (Tamil)</option>
                                    <option value="te_IN">India (Telugu)</option>
                                    <option value="it_IT">Italy</option>
                                    <option value="ja_JP">Japan</option>
                                    <option value="sw_KE">Kenya</option>
                                    <option value="ko_KR">Korea (South)</option>
                                    <option value="es_LA">Lao</option>
                                    <option value="my_MM">Myanmar Burma)</option>
                                    <option value="ms_MY">Malaysia</option>
                                    <option value="ff_NG">Nigeria (Fulah)</option>
                                    <option value="ha_NG">Nigeria (Hausa)</option>
                                    <option value="nl_NL">Netherlands</option>
                                    <option value="nb_NO">Norway</option>
                                    <option value="tl_PH">Philippines</option>
                                    <option value="ur_PL">Pakistan</option>
                                    <option value="pl_PL">Poland</option>
                                    <option value="pt_PT">Portugal</option>
                                    <option value="ro_RO">Romania</option>
                                    <option value="ru_RU">Russia</option>
                                    <option value="ar_AR">Saudi Arabia</option>
                                    <option value="es_ES">Spain</option>
                                    <option value="sv_SE">Sweden</option>
                                    <option value="sk_SK">Slovakia</option>
                                    <option value="th_TH">Thailand</option>
                                    <option value="tr_TR">Turkey</option>
                                    <option value="zh_TW">Taiwan</option>
                                    <option value="en_GB">United Kingdom</option>
                                    <option value="en_US">United States America</option>
                                    <option value="vi_VN">Vietnam</option>
                                    <option value="af_ZA">South Africa</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {showTable === true ? <KeywordTable data={{ data: searchKeywordData, keyword: searchKeyword }} /> : null}
                </div>
            </div>
        );
    }
}

export default SearchComponent;