import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import CsvDownload from 'react-json-to-csv';
import { FaArrowDown, FaGoogle, FaFacebook } from "react-icons/fa";
// import "react-table/react-table.css"
function TableComponent(data) {

    const [cols, setCols] = useState(['#', 'Interest name', 'Audience size', 'Topic', 'Search'])

    let newData = [];
    data.data.data.forEach(row => {
        newData.push({
            'Interest name': row['name'],
            'Audience size': row['audience_size'],
            'Description': row['description'],
            'Topic': row['topic']
        })
    });

    const searchOnIconClick = (e, value, type) => {
        e.preventDefault();
        if (type === 'google')
            window.open("https://www.google.com/search?q=" + value + "", "_blank");
        else
            window.open("https://www.facebook.com/search/pages?q=" + value + "", "_blank");
    }

    return (
        <div className="container">
            <div className="tableDiv">
                <div className="top-div">
                    <div className="top-div_search">
                        <span>Search results for : <strong>{data.data.keyword}</strong></span>
                    </div>
                    <div className="top-div_export">
                        <CsvDownload data={newData} filename="fb-interests.csv" className="btn export-csv">
                            <FaArrowDown className="export_icon" /> Export CSV
                        </CsvDownload>
                    </div>
                </div>
                <table id="table-to-xls" className="table table-hover">
                    <thead>
                        <tr>
                            {cols.map((item, key) => {
                                return (
                                    <th key={key}>{item}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            newData.map((row, key) => {
                                const search = row['Interest name'].replace(" ", "+");
                                return (
                                    <tr key={key}>
                                        <td key={key + '_0'}>{key + 1}</td>
                                        <td key={key + '_1'}><Link to={`/${row[cols[1]]}`} >{row[cols[1]]}</Link></td>
                                        <td key={key + '_2'}>{row[cols[2]]}</td>
                                        <td key={key + '_3'}>{row[cols[3]] !== '' ? row[cols[3]] : "-"}</td>
                                        <td key={key + '_4'}>
                                            <FaGoogle className="icons" onClick={(e) => searchOnIconClick(e, search, 'google')}></FaGoogle>
                                            <FaFacebook className="icons" onClick={(e) => searchOnIconClick(e, search, 'fb')}></FaFacebook>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TableComponent;