import React, { Component } from 'react';
import KeywordTable from './TableComponent';
import axios from 'axios';

class AdvanceSearchComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: null,
            showTable: false
        }
    }

    componentDidMount() {
        const url = "https://graph.facebook.com/search?type=adinterestsuggestion&interest_list=['" + this.props.keyword + "']&limit=1000&locale=en_US&access_token=2840078976085595|mJkR2-rVYjp7TbFh2_ngn2FB_uM"
        console.log(url)
        axios({
            method: 'get',
            url: url
        }).then(res => {
            this.setState({
                data: res.data.data,
                showTable: true
            })
        })
    }

    render() {
        return (
            <div className="container">
                {this.state.showTable === true ? <KeywordTable data={{data: this.state.data, keyword: this.props.keyword}}/> : null}
            </div>
        );
    }
}

export default AdvanceSearchComponent;