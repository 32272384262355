import React, { Component } from 'react';
import axios from 'axios';

class ContactComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: ''
        }
    }

    onNameChange(event) {
        this.setState({ name: event.target.value })
    }

    onEmailChange(event) {
        this.setState({ email: event.target.value })
    }

    onMessageChange(event) {
        this.setState({ message: event.target.value })
    }

    handleSubmit(e) {
        e.preventDefault();
        console.log(this.state);
        axios({
            method: "POST",
            url: "https://ilinks.io/sendHelpEmail",
            data: 'data=' + JSON.stringify(this.state),
        }).then((response) => {
            if (response.data.result === 'success') {
                alert("We have received your request. We will get in touch with you soon");
                this.resetForm()
            } else if (response.data.result === 'failed') {
                alert("Message failed to send.")
            }
        })
    }

    resetForm() {

        this.setState({ name: '', email: '', message: '' })
    }

    render() {
        return (
            <div className="container">
                <div className="contact-div">
                    <h3 className="mt-2" style={{ textAlign: 'center', color: '#5d4f9f' }}>Contact Us</h3>
                    <form id="contact-form" className="mt-4" onSubmit={this.handleSubmit.bind(this)} method="POST">
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input type="text" className="form-control" value={this.state.name} onChange={this.onNameChange.bind(this)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Email address</label>
                            <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea className="form-control" rows="5" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
                        </div>
                        <button type="submit" className="btn btn-success">Submit</button>
                    </form>
                </div>
            </div>
        );
    }

}

export default ContactComponent;